.stdDashTop {
  background-color: rgba(3, 158, 158, 0.7);
  text-align: center;
  width: 90%;
  max-height: 60px;
  margin: 6px auto;
  padding: 0 0 12px 0;
  position: relative;
  box-shadow: 0 8px 6px -6px black;
}
.stdDashtitle {
  font-size: 1.6rem;
}
.teacherDetails {
  text-align: left;
  border: 1px solid grey;
  width: 85%;
  max-width: 480px;
  margin: 15px auto;
  padding: 15px;
}
.teacherInfoTitle { 
  text-align: center;
  font-size: 1.2rem;
}
@media only screen and (min-width: 450px){
  .stdDashTop {
    width: 98%;
    max-height: 80px;
    margin: 0;
    padding: 9px;
  }
  
}
