.rosterItem {
  border: 1px solid grey;
  border-radius: 9px;
  width: 85%;
  max-width: 400px;
  margin: 9px auto;
  padding: 9px;
  background-color: white;
  text-align: center;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2), 0 3px 10px 0 rgba(0, 0, 0, 0.19);
}
.rosterItem:hover {
  box-shadow: 0 2px 4px 6px rgba(0, 0, 0, 0.2);
}
.stdLink {
  color: rgb(82, 82, 82);
  margin: 0;
  padding: 9px 15px;
}

@media only screen and (min-width: 840px) {
  .rosterItem {
    margin: 15px auto;
  }
}

.div1 {
  background-color: rgb(186, 195, 255);
}
.div2 {
  background-color: rgb(168, 255, 168);
}
.div3 {
  background-color: rgb(248, 185, 185);
}
.div4 {
  background-color: rgb(252, 214, 143);
}
.div5 {
  background-color: rgb(255, 255, 156);
}
.div6 {
  background-color: rgb(240, 145, 240);
}
.div7 {
  background-color: rgb(157, 252, 252);
}

