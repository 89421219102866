.newLessonTop {
  background-color: rgba(3, 158, 158, 0.7);
  text-align: center;
  width: 100%;
  margin: 0;
  padding: 9px 18px;
  position: relative;
  box-shadow: 0 6px 6px -4px rgb(27, 27, 27);
}
.backToDash{
  position: absolute;
  top: 2px;
  right: 12px;
}
.smallBox {
  margin-bottom: 6px;
  background-color: rgb(250, 250, 250);
  max-width: 300px;
}
.newLessonHead {
  font-size: 1.5rem;
  line-height: 1.7rem;
  margin: 15px 0 9px 0;
  color: rgb(70, 70, 70);
}
.sessionNotes {
  border: 1px solid grey;
  border-radius: 6px;
  width: 94%;
  margin: 18px auto 12px auto;
  padding: 9px;
  background-color: white;
}
#sessionDate {
  display: inline-block;
  max-width: 270px;
}
#nextInfo{
  width: 95%;
  margin-top: 3px;
  background-color: rgb(250, 250, 250);
}
.saveNewNotes {
  float: right;
  margin-bottom: 9px;
}

@media only screen and (min-width: 450px){
  .newLessonTop {
    width: 100%;
    max-height: 80px;
    margin: 0;
    padding: 9px 0;
  }
  #nextInfo{
    max-width: 500px;
  }
  
}


