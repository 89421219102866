
a {
  text-decoration: none;
}
.App {
  text-align: center;
  width: 100%;
  background-color: rgb(233, 233, 193);
  padding-bottom: 60px;
  padding-top: 15px;
  margin: 0;
}

@media only screen and (min-width: 450px){

.App {
  background-color: rgb(233, 233, 193);
  border: 9px solid rgba(3, 158, 158, 0.7);
  width: 85%;
  min-height: 600px;
  padding: 0;
  padding-bottom: 60px;
  margin: 0 auto;
}

}

@media only screen and (min-width: 750px){
  .App {
    background-color: rgb(233, 233, 193);
    border: 0;
    width: 100%;
    min-height: 600px;
    padding: 0;
    padding-bottom: 60px;
    margin: 0;
  }
    
}