.landingPageTop {
  text-align: center;
  background-color: rgba(3, 158, 158, 0.7);
  width: 100%;
  margin: 0px auto 9px auto;
  padding: 6px;
  box-shadow: 0 8px 6px -6px black;
}
h1 {
  margin: 12px 0;
  color: rgb(55,55,55);
}
.tempGoTo {
  font-size: 1.2rem;
  font-style: italic;
  font-weight: 600;
  color: rgb(55,55,55);
  border: 1px solid grey;
  background-color: rgba(210, 180, 140, 0.6);
  width: 75%;
  max-width: 330px;
  margin: 24px auto;
  text-align: center;
  padding: 9px 0;
}
.tempGoTo:hover {
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2), 0 3px 9px 0 rgba(0, 0, 0, 0.15);
  background-color: rgb(232, 245, 250);
  }
.tempP {
  margin: 6px 0;
}
.tempP:hover {
  color: green;
}
.appInfo {
  width: 80%;
  max-width: 400px;
  background-color: rgb(232, 245, 250);
  border-radius: 60px;
  margin: 6px auto;
  text-align: center;
  padding: 6px;
}
.trackerAbout {
  font-size: 1.4rem;
  line-height: 1.6rem;
  margin: 9px 0;
}
.appInfoP {
  font-size: 1rem;
  line-height: 1.3rem;
}

@media only screen and (min-width: 450px){
  .landingPageTop {
    width: 100%;
    padding: 6px 0;
    margin: 0;
  }
  .tempGoTo{
    max-width: 300px;
  }
  .appInfo {
    max-width: 480px;
  }

}

@media only screen and (min-width: 700px){
  
}