.studentDetails {
  width: 85%;
  max-width: 420px;
  text-align: left;
  border: 1px solid grey;
  border-radius: 3px;
  margin: 15px auto;
  padding: 6px 15px;
  background-color: white;
}
.editStudent {
  float: right;
}
.parag{
  margin: 4px;
}
.details{
  font-size: 1rem;
  font-weight: 600;
}
