
.topicBanner { 
  width: 95%;
  max-width: 270px;
  margin: 24px auto 9px auto;
  padding: 3px 9px;
  text-align: left;
  font-size: 1rem;
  font-weight: 500;
  background-color: rgba(210, 180, 140, 0.6);
}
.topicName {
  font-style: italic;
}
.topicItem {
  text-align: center;
  border: 1px solid grey;
  background-color: rgb(245, 245, 221);
  width: 85%;
  max-width: 360px;
  margin: 9px auto;
  padding: 0 12px;
}
.topicItemTitle {
  font-size: 1rem;
  margin: 6px 0;
}
.topicItemP{
  font-size: 0.8rem;
  line-height: 1rem;
  margin: 6px 0;
}

@media only screen and (min-width: 450px){
  .topicBanner { 
    max-width: 480px;
    }
  .topicItem {
    width: 85%;
    max-width: 420px;
  }
}
