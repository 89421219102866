.specificLessonTop {
  background-color: rgba(3, 158, 158, 0.7);
  text-align: center;
  width: 100%;
  margin: 0;
  padding: 9px 18px;
  position: relative;
  box-shadow: 0 6px 6px -4px rgb(27, 27, 27);
}
.toDashboard {
  position: absolute;
  top: 1px;
  right: 3px;
}

.singleLessonTitle{
  font-size: 1.5rem;
  line-height: 1.7rem;
  margin: 15px 0 9px 0;
  color: rgb(70, 70, 70);
}
.singleLessonP{
  font-size: 1.3rem;
  font-weight: 500;
  margin: 15px 0 6px 0;
}
.italic {
  font-style: italic;
  font-weight: 400;
}
.lessonTopicDiv {
  border: 1px solid grey;
  border-radius: 4px;
  text-align: left;
  width: 85%;
  max-width: 480px;
  margin: 15px auto;
}
.topicHeader {
  background-color: rgba(210, 180, 140, 0.6);
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  margin: 0;
  padding: 6px 9px;
}
.topicTitle {  
  font-size: 1rem;
  text-align: left;    
  margin: 0;
  padding: 0;
}
.italicspan {
  font-style: italic;
}
.topicDetails {
  padding: 9px 12px;
  background-color: white;
  border-bottom-right-radius: 4px;
  border-bottom-left-radius: 4px;
}
.topicDetails p {
  margin: 3px;
}
.nextSessionTitle {
  font-size: 1rem;
  margin: 0;
  padding: 0;
}
.nextSessionDetails {
  text-align: left;
  border: 2px solid lightgrey;
  border-style: inset;
  background-color: white;
  width: 85%;
  max-width: 480px;
  margin: 12px auto;
  padding: 9px 15px;
}
.nextSessionDetails p {
  margin: 6px 0;
}
@media only screen and (min-width: 450px){
  .specificLessonTop {
    width: 100%;
    max-height: 80px;
    margin: 0;
    padding: 9px 0;
  }
  
}
@media only screen and (min-width: 840px) {
  
.row2 {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  height: 100%;
  width: 100%;
  overflow-y: hidden;
  margin: 0;
  padding-top: 15px;
}
.columnA2 {
  display: flex;
  flex-direction: column;
  flex-basis: 100%;
  flex: 1;
  min-width: 304px;
  max-width: 525px;
  overflow: auto;
  padding-left: 24px;
}
.columnB2 {
  display: flex;
  flex-direction: column;
  flex-basis: 100%;
  flex: 2;
  height: 100%;
  min-width: 450px;
  overflow-y: auto;
  margin: 0;
}
.lessonTopicDiv {
  width: 85%;
  max-width: 600px;
  margin: 15px auto;
}


}
