.dashTop {
  background-color: rgba(3, 158, 158, 0.7);
  text-align: center;
  width: 100%;
  margin: 0;
  padding: 9px 18px;
  box-shadow: 0 6px 6px -4px rgb(27, 27, 27);
}
.dashbdHead {
  color: rgb(70, 70, 70);
  font-size: 1.5rem;
  line-height: 1.7rem;
  margin: 9px 15px;
}
.newLesson2 {
  font-size: 1rem;
  font-weight: 600;
  background-color: rgba(210, 180, 140, 0.6);
  color: rgb(55, 55, 55);
  width: 70%;
  max-width: 300px;
  margin: 18px auto 12px auto;
  padding: 9px;
  border-radius: 3px;
}
.newLesson2:hover {
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2), 0 3px 9px 0 rgba(0, 0, 0, 0.3);
}
.nL2 {
  color: rgb(55,55,55);
}

.addNewStd {
  width: 90%;
  max-width: 500px;
  margin: 9px auto;
  border: 1px solid grey;
  border-radius: 3px;
}
.addStudentFormHeader:hover {
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2), 0 3px 10px 0 rgba(0, 0, 0, 0.19);
}
.arrowpointers {
  font-size: 2.7rem;
  line-height: 1.7rem;
  position: absolute;
  right: 0;
}
.italicSpan {
  text-align: left;
  font-style: italic;
}
@media only screen and (min-width: 450px){
  .dashTop {
    width: 100%;
    max-height: 80px;
    margin: 0;
    padding: 12px 0;
  }
}
@media only screen and (min-width: 840px) {
  
.row {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  height: 100%;
  width: 100%;
  overflow-y: hidden;
  margin: 0;
  padding-top: 15px;
}
.columnA3 {
  display: flex;
  flex-direction: column;
  flex-basis: 100%;
  flex: 1;
  min-width: 315px;
  max-width: 525px;
  overflow: auto;
}
.columnB3 {
  display: flex;
  flex-direction: column;
  flex-basis: 100%;
  flex: 1;
  height: 100%;
  min-width: 440px;
  overflow-y: auto;
  margin: 0;
}
.newLesson2 {
  margin: 23px auto;
}

}