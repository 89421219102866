.stdTop {
  background-color: rgba(3, 158, 158, 0.7);
  text-align: center;
  width: 100%;
  margin: 0 0 15px 0;
  padding: 6px;
  position: relative;
  box-shadow: 0 6px 6px -4px rgb(27, 27, 27);
}
.stdTopTitle {
  padding-top: 15px;
  font-size: 1.5rem;
  line-height: 1.7rem;
  color: rgb(70, 70, 70);
  margin: 0;
}
.toDashboard {
  position: absolute;
  top: 2px;
  right: 12px;
}
.stdName {
  font-size: 1.7rem;
  line-height: 1.7rem;
  color: rgb(70, 70, 70);
  font-weight: 600;
  font-style: italic;
  margin: 0px;
}
.newLesson {
  font-size: 1rem;
  font-weight: 600;
  background-color: rgba(210, 180, 140, 0.6);
  color: rgb(55, 55, 55);
  width: 70%;
  max-width: 300px;
  margin: 18px auto 9px auto;
  padding: 9px;
  border-radius: 3px;
}
.newLesson:hover {
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2), 0 3px 9px 0 rgba(0, 0, 0, 0.15);
}
.previousLessons {
  width: 95%;
  margin: 24px auto;
  padding: 0 0 60px 0;
  border: 4px solid rgb(210, 180, 140);
  background-color: white;
  min-height: 180px;
  border-radius: 9px;
}
.previousLessonsTitle{
  text-align: center;
  background-color: rgba(210, 180, 140, 0.9);
  color: rgb(55, 55, 55);
  font-size: 1.2rem;
  margin: 0;
  padding: 6px 0 9px 0;
}
@media only screen and (min-width: 450px){
  .stdTop {
    width: 100%;
    max-height: 80px;
    margin: 0;
    padding: 9px 0;
  }
  .stdTopTitle {
    padding-top: 12px;
    margin: 0;
  }
}

@media only screen and (min-width: 840px) {
.row {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  height: 100%;
  width: 100%;
  overflow-y: hidden;
  margin: 0;
  padding-top: 15px;
}
.columnA {
  display: flex;
  flex-direction: column;
  flex-basis: 100%;
  flex: 2;
  min-width: 304px;
  max-width: 525px;
  overflow: auto;
}
.columnB {
  display: flex;
  flex-direction: column;
  flex-basis: 100%;
  flex: 3;
  height: 100%;
  min-width: 450px;
  overflow-y: auto;
  margin: 0;
}
.previousLessons {
  width: 90%;
  margin: 12px auto;
}
}