.addNewTopicBtn {
  float: right;
  border-radius: 9px;
  padding: 3px 6px;
  line-height: 1.2rem;
}
.plus {
  font-size: 1.2rem;
  line-height: 0.3rem;
  font-weight: 600;
  vertical-align: center;
}
#topicsArea{
  width: 100%;
  min-height: 100px;
  border-top: 2px solid rgba(70, 70, 70, 0.7);
  border-bottom: 2px solid rgba(70, 70, 70, 0.7);
  margin: 9px 0;
  display: flex;
  flex-flow: row wrap;
  align-content: space-around;
}
.newTopicArea {
  width: 95%;
  min-height: 140px;
  margin: 18px 9px;
  padding: 6px;
  background-color: rgba(236, 236, 195, 0.6);
  border-radius: 3px;
  box-shadow: 0 3px 6px 2px rgba(0,0,0,0.3);
}
.topicOption {
  display: inline-block;
  background-color: rgb(250, 250, 250);
}
.otherLabel {
  font-size: 0.9rem;
  display: inline-block;
}
.otherBox {
  display: inline-block;
  background-color: rgb(250, 250, 250);
}

.anotherNotesField {
  width: 98%;
  background-color: rgb(250, 250, 250);
  margin-top: 9px;
}


@media only screen and (min-width: 450px){
  .newTopicArea {
    max-width: 400px;
  }  


}