
.lessonItem {
  text-align: center;
  border: 1px solid grey;
  background-color: rgb(245, 245, 221);
  width: 85%;
  max-width: 360px;
  margin: 15px auto;
  padding: 6px;
}
.lessonItem:hover {
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2), 0 3px 10px 0 rgba(0, 0, 0, 0.19);
}
.sessionTitle {
  font-size: 1rem;
  line-height: 1.2rem;
  margin: 0;
  color: rgb(65, 65, 65);
}
